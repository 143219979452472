import React from 'react';
import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import Layout from '../../layout';

import CategoryList from '../../components/categoryList';
import Detail from '../../components/detail';
import ProductList from '../../components/productList';
import './style.css';

const Products = props => {
  return (
    <Router className="router-container">
      <CategoryList path="/products/" />
      <ProductList path="/products/:categoryId" />
      <Detail path="/products/:categoryId/:id" />
    </Router>
  );
};

Products.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Products;
