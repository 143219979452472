import React, { useState, useEffect, useRef } from 'react';
import Gallereact from 'gallereact';
import { Table } from 'antd';
import Pill from '../pill';
import { products, materials } from '../../data/data.json';
import './style.css';

const { Column } = Table;

const getText = text => text.split('\n').map((quote, i) => <p key={+i}>{`${quote}`}</p>);

const getMaterialText = text =>
  text
    .split('• ')
    .slice(1)
    .map((quote, i) => <p key={+i}>{`${quote}`}</p>);

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop - 150);

const Detail = ({ id }) => {
  const materialInfoRef = useRef(null);
  const tableInfoRef = useRef(null);
  const [product, setProduct] = useState({});
  const [options, setOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setProduct(products[+id]);
    setOptions(
      products[+id].refs.map(({ ref, format, innerSize, outerSize }, key) => ({
        key,
        ref,
        format,
        innerSize,
        outerSize,
      })),
    );
  }, []);

  useEffect(() => {
    if (product && product.id !== undefined) {
      fetch(`https://res.cloudinary.com/jcr/image/list/prod${product.id}.json`)
        .then(res => (res.status === 200 ? res.json() : Promise.reject(res)))
        .then(res => {
          if (res && res.resources) {
            setImages(
              res.resources.map(image => ({
                image: `https://res.cloudinary.com/jcr/image/upload/v1591267259/${image.public_id}.jpg`,
              })),
            );
          }
        })
        .catch(err => console.log('ERR', err)) // eslint-disable-line no-console
        .then(() => setLoading(false));
    }
  }, [product]);

  const renderGallery = () => (
    <Gallereact
          images={images}
          secondaryColor="rgb(50, 57, 77)"
          dotStyle={{
            width: '25px',
            height: '3px',
            borderRadius: 0,
            margin: '0px',
          }}
          slideStyle={{
            width: "80%",
            height: "90%",
            margin: "5% 10%",
            borderRadius: '0.5rem',
          }}
          displayArrow
          cover
          autoPlay
        />
  );

  const renderGalleryPlaceholder = () => (
    <div class="gallery-placeholder">
       {!loading &&  <img alt="pic" />}
    </div>
  );

  const renderProduct = () => (
    <div className="details-content-container">
      <div className="slider_container">
        {(images && images.length) ?  renderGallery() : renderGalleryPlaceholder()}
      </div>
      <div className="details-title_container">
        <div className="details_title">{product.name}</div>
        {/* {product.materialId && (
          <div className="details-title-material_container">
            <p>Material:</p>
            <p>{materials[product.materialId].name}</p>
          </div>
        )} */}
        {product.descProduct && (
          <div className="details-description">{getText(product.descProduct)}</div>
        )}
        {product.id && (
          <div className="details-pills_container">
            {product.materialId && (
              <Pill content="Material" onClick={() => scrollToRef(materialInfoRef)} />
            )}
            <Pill content="Guia de referencies" onClick={() => scrollToRef(tableInfoRef)} />
          </div>
        )}
      </div>
    </div>
  );

  const renderCardInfo = () => (
    <div className="details-info-card_container">
      <div className="details-info-card_title">Informació</div>
      {product.descMaterial && (
        <div className="details-info-card_section" ref={materialInfoRef}>
          <p className="details-info-card_sub-title">Material</p>
          {getMaterialText(product.descMaterial)}
        </div>
      )}
      <div className="details-info-card_section" ref={tableInfoRef}>
        <div className="details-info-card_sub-title">Guia de referencies</div>
        <div className="details-table_container">
          <Table dataSource={options} className="details-table" pagination={false}>
            <Column title="Ref." dataIndex="ref" align="center" fixed="left"/>
            {options[0].format && <Column title="Format" dataIndex="format" align="center" />}
            {options[0].innerSize && (
              <Column title="Mides interiors" dataIndex="innerSize" align="center" />
            )}
            {options[0].outerSize && (
              <Column title="Mides exteriors" dataIndex="outerSize" align="center" />
            )}
          </Table>
        </div>
      </div>
    </div>
  );

  return product.id ? (
    <div className="details-container">
      {renderProduct()}
      {renderCardInfo()}
    </div>
  ) : (
    <div />
  );
};

export default Detail;
