import React from 'react';
import { Link } from 'gatsby';

import './style.css';
import { getZeros } from '../../utils/images';
import { cloudinaryImgPath } from '../../config';

const CategoryListCard = ({ category }) => {
  return (
    <div className="itemContainer">
      <Link to={`/products/${category.id}`}>
        <div className="picContainer">
          <img
            className="pic"
            src={`${cloudinaryImgPath}/cat${getZeros(category.id)}/cat${getZeros(category.id)}.jpg`} // eslint-disable-line import/no-dynamic-require, global-require
            alt="pic"
          />
        </div>
        <div className="titleContainer">
          <p className="title">{category.name}</p>
        </div>
      </Link>
    </div>
  );
};

export default CategoryListCard;
