import React from 'react';

import CategoryListCard from '../categoryListCard/index';
import { categories } from '../../data/data.json';
import './style.css';

const CategoryList = () => {
  const renderCards = () =>
    Object.values(categories).map(category => (
      <CategoryListCard key={category.id} category={category} />
    ));

  return (
    <div className="content">
      <div className="container-categories">{renderCards()}</div>
    </div>
  );
};

export default CategoryList;
