import React from 'react';
import './style.css';

const Pill = ({ title, content, value, active, onClick }) => {
  return (
    <div className="pill-container">
      {title && <div className="pill-title">{title}</div>}
      <button
        type="button"
        className={`pill-content pill-content${active ? '-active' : ''}`}
        onClick={onClick ? () => onClick(value) : () => {}}
      >
        {content}
      </button>
    </div>
  );
};

export default Pill;
